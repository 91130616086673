import React from "react";
import "../index.css";

const WebPComponent = (props) => {
  const altText = props.altText ? props.altText : "";
  const id = props.id ? props.id : "";
  return (
    <picture>
      <source srcSet={props.webp} type="image/webp" />
      <source srcSet={props.png} type="image/png" />
      <img src={props.png} alt={altText} id={id} />
    </picture>
  );
};

const Content = (props) => {
  return (
    <div className="grid-content">
      <div id="img-container">
        <WebPComponent
          webp={"img/pink-opt-s.webp"}
          png={"img/pink-opt-s.png"}
          altText={"Raghads pic"}
          id={"id2"}
        />
        <WebPComponent
          webp={"img/pink-opt-s.webp"}
          png={"img/pink-opt-s.png"}
          altText={"Raghads pic"}
          id={"id1"}
        />
      </div>
    </div>
  );
};
export default Content;
