import React from "react";
import "../index.css";
const Header = (props) => {
  return (
    <div id="grid-header">
      <ul className="menu">
        <li>
          <a href="/">
            <p className="header-name">Home</p>
          </a>
        </li>
        <li>
          <a href="/blogs">
            {" "}
            <p className="header-name">Blog</p>
          </a>
        </li>
        <li>
          <a href="https://www.shecodestooiq.com">
            {" "}
            <p className="header-name">SheCodesToo;</p>
          </a>
        </li>
        <li>
          <a href="/cv.pdf">
            {" "}
            <p className="header-name">CV</p>
          </a>
        </li>{" "}
      </ul>
    </div>
  );
};
export default Header;
