import React, { useEffect, useState } from "react";
import '../blogpage.css'
import Footer from './Footer'
import Header from "./Header";
import Nameoverlay from './Nameoverlay'
import ReactMarkdown from 'react-markdown';
import '../github-markdown.css';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/github.css';
import LoadingCircle from './LoadingCircle.js'

import {
    useParams
} from "react-router-dom";

const SinlgeBlogsPage = (props) => {
    hljs.registerLanguage('javascript', javascript);

    let params = useParams();
    const url = 'https://raghadhav.com/md-blogs/' + params.id + '.md';

    const [markdown, setMarkdown] = useState('');
    if (url) fetch(url).then(res => res.text()).then(text => setMarkdown(text))

    useEffect(() => {
        console.log('highlight called')
        hljs.highlightAll();
    }, [markdown])

    // 1. Fetch URL of MD file.
    // 2. User fetches MD file.
    // 3. User converts MD file to HTML on front-end.

    // 0. When we deploy: Convert MD to HTML, change link in DB to HTML.
    // 1. Fetch URL of blog
    // 2. User fetches HTML page and renders.

    // Phonebook App
    // GitHub link:
    // Technologies used: 
    // Description of the app:

    // 1. Book App
    // 2. Blog App
    // 3. Phonebook App
    // 4. SheCodesToo

    // Adding  new app
    // 1. Add new path to nginx -> node at some unused port
    // 2. Run node app on same port using PM2

    return (
        <div className='blog-detail-container'>
            {<div className='first-container-pp'>
                <div className="grid-container">
                    <Header header={''}> </Header>
                </div>
                <Nameoverlay name={''}></Nameoverlay>
            </div>}
            <div className='single-blog-body'  >
                <div className=''>
                    {(markdown === '')
                        ? <LoadingCircle />
                        : <ReactMarkdown children={markdown} className="markdown-body" />
                    }
                </div>

            </div>
        </div>
    );
};
export default SinlgeBlogsPage;