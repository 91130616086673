import '../loader.css'
import React from "react"
const LoadingCircle = () => {
    const [showLoad, setShowLoad] = React.useState(false);

    React.useEffect(() => {
        if (!showLoad) {
            setTimeout(() => {
                setShowLoad(true);
            }, 3000)
        }
    }, [])

    if (showLoad) {
        return <div className="loader"></div>
    }
    else {
        return <div></div>
    }
}
export default LoadingCircle;

// initial render => register effect => call effect (after render) => state change => render => repeat from step 3

// 1. Initial render. Register Effect. showLoad = false. return <div/>
// 2. Trigger Effect => setTimeout (setShowLoad will trigger after 5 seconds).
// 3. 5s later => showLoad = true. State has changed => trigger render.
// 4. Second render => showLoad now true => return loader  
// 5. Use effect triggered after render => showLoad already true => no state change => component never calls render again 