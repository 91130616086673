import React from "react";
import "../index.css";
const Nameoverlay = (props) => {
  return (
    <a href="/">
      <div id="name-overlay">
        <pre>{`Raghad\n\tal-Abboodi`}</pre>
      </div>
    </a>
  );
};
export default Nameoverlay;
