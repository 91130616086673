import React from "react";
import '../index.css';
import getAll from '../services/fetchblog'
import {
    useParams
} from "react-router-dom";
import blogMDUrlToRenderUrl from './BlogsUtils'

const Fourth = (props) => {
    const [blogs, setBlogs] = React.useState([]);

    React.useEffect(() => {
        getAll().then(response => setBlogs(response));
    }, [])

    let renderBlogs = blogs.map((b) => {
        b.reactUrl = blogMDUrlToRenderUrl(b.url)
        return b;
    })


    if (blogs.length > 0) {
        return (
            <div> 
                <div className='hline'><h3>Recent Blogs</h3> </div>
                <div className='blog-container'>
                    {renderBlogs.map((b) => {
                        return (
                            <a href={b.reactUrl} className='title-a'>
                                <div className="blog-class">
                                    <div className="blog-img">
                                        <img src='img/js.svg' />
                                    </div>
                                    <div className="blog-content">
                                        <h4>{b.title}</h4>
                                        <p>{b.date}</p>
                                    </div>
                                </div>
                            </a>
                        )
                    })}
                </div>
                <div className='more-blogs'>
                    <a href='/blogs'>
                        <p>More Blogs</p>
                        <img src='img/click.svg' id='more-img' />
                    </a>
                </div>
            </div>
        );
    }
    else {
        return <div></div>;
    }

};
export default Fourth;