import '../index.css'
import React from "react";
import Header from './Header'
import Content from './Content'
import Body from './Body';
import Icon from './Icon';
import Nameoverlay from './Nameoverlay'
import Background from './Background'
import Second from './Second'
import Third from './Third'
import Fourth from './Fourth'


const Home = (props) => {
    return (
        <div className='main-container'>
            <div className='first-container'>
                <div className="grid-container">
                    <Header header={''}> </Header>
                    <Content content={''}> </Content>
                    <Body body={''}></Body>
                    <Icon icon={''}></Icon>
                </div>
                <Nameoverlay name={''}></Nameoverlay>
                <Background name={''}></Background>
            </div>
            <div className='section'>
                <Second second={''}></Second>
            </div>
            <div className='section'>
                <Third third={''}></Third>
            </div>
            <div className='section'>
                <Fourth fourth={''}></Fourth>
            </div>
        </div>
    );
};
export default Home;