import React from "react";
import "../index.css";
import "../about.css";
const Second = (props) => {
  const aboutArray = [
    {
      sectionTitle: "Skills",
      items: [
        {
          title: "Great",
          subtitle: [
            "JavaScript, TypeScript, HTML, CSS, Angular, React, Git, Jira",
          ],
        },
        {
          title: "Good",
          subtitle: ["Jasmine, Jest, Node.js, Redux, NgRx, Nginx, Mongoose"],
        },
        {
          title: "Familiar",
          subtitle: ["PHP, Python, C/C++"],
        },
      ],
    },
    {
      sectionTitle: "Eduction",
      items: [
        {
          title: "University of Bristol",
          subtitle: ["MSc. Advanced Computing"],
          date: "Jul 2016",
        },
        {
          title: "University of Babylon",
          subtitle: ["BSc. Computer Science"],
          date: "Jul 2011",
        },
      ],
    },
    {
      sectionTitle: "Experience",
      items: [
        {
          title: "Trainer and Consultant",
          subtitle: [
            "Currently providing project management services to BBS",
            "Provide consultancy services to software institutions and organisations.",
            "Offer software training sessions to these organizations",
          ],
          date: "Oct 2023 - present",
        },
        {
          title: "Software Engineer",
          subtitle: [
            "Developed and maintained Swedbank online banking website",
            "Worked within the lending team on the front-end, which utilised the Angular framework",
            " Implemented new functionality to apply for car loans and mortgage amortisations.",
            "Migrated legacy AngularJS code to modern Angular.",
            "Wrote unit tests using the Jasmine testing framework.",
            "Troubleshot and debugged system bugs to solve customer issues.",
          ],
          date: "Mar 2022 - Jun 2023",
        },
        {
          title: "Founder and Director of She Codes Too",
          subtitle: [
            "Founded and led a charity-based initiative to teach coding to Iraqi women",
            "Formed and lead a team of five people to manage to initiative.",
            "Designed the teaching curriculum and organised remote and in-person bootcamps.",
            "Secured funding from the UN Development Programme (UNDP).",
            "Built partnerships and collaborations with organisations such as Meta (Facebook), UNDP, and the Baghdad Business School.",
          ],
          date: "Sep 2020 - present",
        },
        {
          title: "Assistant Lecturer and Department Coordinator",
          subtitle: [
            "Designed the curriculum and taught a variety of computer science-related courses.",
            "Worked with the department chair to manage and organise the undergraduate syllabus.",
          ],
          date: "Dec 2016 - Mar 2022",
        },
      ],
    },
  ];
  return (
    <div className="about-container">
      {aboutArray.map((section) => {
        return (
          <div className="about-section">
            <div className="about-title">
              <h3>{section.sectionTitle}</h3>
            </div>
            <div className="about-content">
              {section.items.map((item) => {
                return (
                  <div className="about-item">
                    <div className="about-item-content">
                      <div className="about-item-title">{item.title}</div>
                      <div className="about-item-subtitle">
                        {item.subtitle.length > 1 ? (
                          item.subtitle.map((sub) => <li> {sub}</li>)
                        ) : (
                          <p>{item.subtitle[0]}</p>
                        )}
                      </div>
                    </div>
                    {item.date ? (
                      <div className="about-item-date">{item.date}</div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Second;
