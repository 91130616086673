import React from "react";
import "../index.css";
const Third = (props) => {
  const projectArray = [
    {
      proName: "",
      proDescription: "",
      proItems: "",
    },
  ];
  return (
    <div className="project-container">
      <div className="hline">
        <h3>Recent Projects</h3>
      </div>
      <div className="project">
        <a href="https://raghadhav.com/phonebook/">
          <h5>Phonebook App</h5>
          <div>
            <h6>Phonebook app to insert, delete, edit from Mongo DB</h6>
            <ul className="ulElement">
              <li>React.js</li>
              <li>JavaScript</li>
              <li>MongoDB</li>
              <li>HTML</li>
              <li>CSS</li>
            </ul>
          </div>
        </a>
      </div>
      <div className="project">
        <a href="https://raghadhav.com/blogapp/">
          <h5>Blog App</h5>
          <div>
            <h6> Add, delete blogs with user authentication app</h6>
            <ul className="ulElement">
              <li>React.js</li>
              <li>JavaScript</li>
              <li>MongoDB</li>
            </ul>
          </div>
        </a>
      </div>
      <div className="project">
        <a href="https://www.shecodestooiq.com/">
          <h5>She Codes Too website</h5>
          <div>
            <h6> Website for She Codes Too Initiative</h6>
            <ul className="ulElement">
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
            </ul>
          </div>
        </a>
      </div>
      <div className="project">
        <a href="https://github.com/raghadhav/loan-calculator">
          <h5>Loan Calculator</h5>
          <div>
            <h6> Web page for calculating loan</h6>
            <ul className="ulElement">
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>Angular</li>
            </ul>
          </div>
        </a>
      </div>
    </div>
  );
};
export default Third;
