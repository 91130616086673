import React from "react";
import "../blogpage.css";
import Footer from "./Footer";
import Header from "./Header";
import Content from "./Content";
import Body from "./Body";
import Icon from "./Icon";
import Nameoverlay from "./Nameoverlay";
import Background from "./Background";
import getAll from "../services/fetchblog";
import blogMDUrlToRenderUrl from "./BlogsUtils";

const BlogsPage = (props) => {
  const [blogs, setBlogs] = React.useState([]);

  React.useEffect(() => {
    getAll().then((response) => setBlogs(response));
  }, []);
  // To take only fist few words of the content
  function ellipsify(str) {
    // if (str.length > 10) {
    //     return (str.substring(0, 10) + "...");
    // }
    // else {
    //     return str;
    // }
  }

  // url = raghadhav.com/md-blogs/call-by-value.md
  // url = /foo/call-by-value

  let renderBlogs = blogs.map((blog) => {
    blog.reactUrl = blogMDUrlToRenderUrl(blog.url);
    return blog;
  });

  return (
    <div className="blog-main-container">
      <div className="first-container-pp">
        <div className="grid-container">
          <Header header={""}> </Header>
        </div>
        <Nameoverlay name={""}></Nameoverlay>
      </div>

      <div className="blog-first-section">
        <h1 class="blog-page-title">
          Explore My <span class="blog-title-text-yellow"> Thoughts</span>
        </h1>
        <div class="card-container">
          <div class="cardItem">
            {renderBlogs.map((blog) => {
              return (
                <div className="\-blog-content">
                  <div className="blog-head">
                    <a href={blog.reactUrl}>
                      {" "}
                      <span class="cardLabel">{blog.title}</span>
                    </a>
                  </div>
                  <div className="blog-text">
                    {" "}
                    {ellipsify(blog.content)}
                    <span class="read-more">
                      <a href={blog.reactUrl}> Read More </a>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogsPage;
