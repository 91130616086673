import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch, Route, Link, useHistory
} from "react-router-dom"


import './index.css'
import Header from './components/Header'
import Content from './components/Content'
import Body from './components/Body';
import Icon from './components/Icon';
import Nameoverlay from './components/Nameoverlay'
import Background from './components/Background'
import Second from './components/Second'
import Third from './components/Third'
import Fourth from './components/Fourth'
import Footer from './components/Footer'
import BlogsPage from './components/BlogsPage'
import SinlgeBlogsPage from './components/SinlgeBlogsPage'
import Home from './components/Home'

const App = () => {

  return (
    <Router>
      <Switch>
        <Route path="/blogs">
          <BlogsPage />
        </Route>
        <Route path="/foo/:id">
          <SinlgeBlogsPage/>
        </Route>
        <Route path="/">
          <Home />
        </Route>

      </Switch>
      <div className='footer'>
                <Footer footer={''}></Footer>
            </div>
    </Router>

  )
};

export default App;
