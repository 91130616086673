import axios from "axios";

const baseUrl = "https://raghadhav.com/blogs-api/api/blogs";

const getAll = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export default getAll;
