import React from "react";
import "../index.css";
const Body = (props) => {
  return (
    <div className="grid-body">
      <div id="body-content">
        <h4>Hello!</h4>
        <h1>I am Raghad</h1>
        <h4>I am a </h4>
        <ul>
          <li>Programmer</li>
          <li>Founder</li>
          <li>Software Trainer and Consultant</li>
        </ul>
      </div>
    </div>
  );
};
export default Body;
