import React from "react";
import '../index.css';
const Icon = (props) => {
    return (
        <div className='grid-icon'>
               <a href='https://github.com/raghadhav/'> <div className='icon-class'><img src="../img/github.svg" className="icons"/></div></a>
               <a href='https://www.youtube.com/user/Raghadhav'><div className='icon-class'><img src="../img/youtube.svg" className="icons"/></div></a>
               <a href='https://www.linkedin.com/in/raghadhav/'><div className='icon-class'><img src="../img/linkedin.svg" className="icons"/></div></a>
        </div>
    );
};
export default Icon;